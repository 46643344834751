import { Container, Row, Col, Toast } from 'react-bootstrap'
import { Item } from './main'
import React, { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';

export class Timer extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            showgift: false,
            show: false,
            show1: false,
            copy: null
        }
    }
    componentDidMount() {
        var countDownDate = new Date(this.props.waktunikah).getTime();

        // Update the count down every 1 second
        var x = setInterval(() => {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })
        }, 1000);
    }
    render() {
        let {days,hours,minutes,seconds } = this.state
        let { cmain,reni,kiki } = this.props
        let bg=cmain
        let fc='white'
        if(reni){
            bg='white'
            fc=cmain
        }
        let {lang}=this.props
        if (kiki){
            return (
                <> <Container id='sectiongold57' className="py-3" style={{backgroundColor:'rgb(226, 149, 143)'}}>
                    <div className=''>
                        <Item>
                            <div data-aos="fade-left" data-aos-duration="1000"
                                className='col-12 col-lg-8 mt-0' >
                                <Item>
                                    <div className='item mx-1 p-2' 
                                    style={{ backgroundColor: 'rgb(230, 46, 122)' ,
                                        width:'80px',
                                        height:'80px',
                                        borderRadius:'40px',
                                       
                                        }}>
                                    <Item>
                                            <span style={{color:fc}}>
                                                HARI
                                                </span>
                                        </Item>
                                        <Item>
                                            <div style={{color:fc}} >
                                                {days}
                                            </div>
                                        </Item>
                                        
                                    </div>
                                   
                                    <div className='item mx-1 p-2' style={{ backgroundColor: 'rgb(230, 46, 122)',
                                        width:'80px',
                                        height:'80px',
                                        borderRadius:'40px',}}>
                                    <Item>
                                            <span style={{color:fc}}>
                                                JAM
                                            </span>
                                        </Item>
                                        <Item>
                                            <div style={{color:fc}}>
                                                {hours}
                                            </div>
                                        </Item>
                                       
                                    </div>
                                    
                                    <div className='item mx-1 p-2' style={{ backgroundColor: 'rgb(230, 46, 122)',width:'80px',
                                        height:'80px',
                                        borderRadius:'40px', }}>
                                    <Item>
                                            <span style={{color:fc}}>
                                                MENIT
                                            </span>
                                        </Item>
                                        <Item>
                                            <div style={{color:fc}}>
                                                {minutes}
                                            </div>
                                        </Item>
                                        
                                    </div>
                                    
                                    <div className='item mx-1 p-2' style={{ backgroundColor: 'rgb(230, 46, 122)',width:'80px',
                                        height:'80px',
                                        borderRadius:'40px', }}>
                                    <Item>
                                            <span style={{color:fc}}>
                                                DETIK
                                            </span>
                                        </Item>
                                        <Item>
                                            <div style={{color:fc}}>
                                                {seconds}
                                            </div>
                                        </Item>
                                        
                                    </div>
                                </Item>
    
                            </div>
                        </Item>
                    </div>
                </Container>
    
    
                </>
            )
        }else{
            return (
                <> <Container id='sectiongold57' className="py-3">
                    <div className='pt-3'>
                        <Item>
                            <div data-aos="fade-left" data-aos-duration="1000"
                                className='col-10 col-lg-8 kotak' style={{ backgroundColor: bg }}>
                                <Item>
                                    <div className='item'>
                                        <Item>
                                            <div style={{color:fc}}>
                                                {days}
                                            </div>
                                        </Item>
                                        <Item>
                                            <span style={{color:fc}}>
                                                {lang=="id"?"Hari":"Days"}
                                                </span>
                                        </Item>
                                    </div>
                                    <div className='dot' style={{color:fc}}>:</div>
                                    <div className='item'>
                                        <Item>
                                            <div style={{color:fc}}>
                                                {hours}
                                            </div>
                                        </Item>
                                        <Item>
                                            <span style={{color:fc}}>
                                                {lang=="id"?"Jam":"Hours"}
                                            </span>
                                        </Item>
                                    </div>
                                    <div className='dot' style={{color:fc}}>:</div>
                                    <div className='item'>
                                        <Item>
                                            <div style={{color:fc}}>
                                                {minutes}
                                            </div>
                                        </Item>
                                        <Item>
                                            <span style={{color:fc}}>
                                                {lang=="id"?"Mnt":"Mins"}
                                            </span>
                                        </Item>
                                    </div>
                                    <div className='dot' style={{color:fc}}>:</div>
                                    <div className='item' >
                                        <Item>
                                            <div style={{color:fc}}>
                                                {seconds}
                                            </div>
                                        </Item>
                                        <Item>
                                            <span style={{color:fc}}>
                                                {lang=="id"?"Dtk":"Secs"}
                                            </span>
                                        </Item>
                                    </div>
                                </Item>
    
                            </div>
                        </Item>
                    </div>
                </Container>
    
    
                </>
            )
        }
        
        
        
    }

}
